h2 {
  color: white;
  font-size: 25px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.experience {
  letter-spacing: 1px;
  margin-top: 3px;
}
