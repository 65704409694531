.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 10%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.about {
  top: 35% !important;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.548);
    text-align: center;
    font-size: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px white;
  }

  .face1 {
    transform: translateZ(100px);
    color: #dd0031;
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #f06529;
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ed4f4;
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

.flat-button {
  color: #ffd700;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 3px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffd700;
  margin-top: 5px;
  margin-right: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover {
    background: #ffd700;
    color: black;
    font-weight: bold;
  }
}

.ima {
  color: rgba(107, 107, 107, 0.856);
}

.ima2 {
  margin-top: 0px;
}

.other {
  margin-bottom: 2px;
}

.big {
  color: white;
  font-weight: 700;
  font-size: 24px;
}

.aboutme {
  letter-spacing: 1px;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 70%;
  overflow: hidden;
  left: 100px;
  max-width: 590px;
  margin-left: 43px;
  margin-right: 0;
}

.skills__skill:hover {
  background: rgb(255, 215, 0, 0.8);
}

.skills__skill {
  padding: 10px 15px;
  margin-bottom: 12px;
  margin-right: 15px;
  font-size: 14px;
  background: rgba(153, 153, 153, 0.1);
  border-radius: 5px;
  font-weight: 600;
  color: white;
  opacity: 0.8;
}

.skillsh2 {
  position: relative;
  top: 70%;
  overflow: hidden;
  left: 100px;
  margin-left: 43px;
  margin-right: 0;
  color: #ffd700;
  opacity: 0.8;
  letter-spacing: 1px;
  font-size: 24px;
}

@media screen and (max-width: 1200px) {
  .stage-cube-cont {
    display: none;
  }

  .flat-button {
    font-size: 10px;
    padding: 8px;
  }

  .skills {
    position: unset !important;
    margin-left: 20px !important;
  }

  .about p {
    max-width: 700px;
    font-size: 16px !important;
    top: 150px !important;
  }

  .skillsh2 {
    top: 2px !important;
    margin-left: 0 !important;
    left: 22px !important;
  }
}
