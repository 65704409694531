.solid-logo {
  width: 600px;
  height: auto;
  margin-left: 620px;
  transition: ease-in;
}

@media screen and (max-width: 1200px) {
  .solid-logo {
    width: 500px;
    margin-left: 40px;
  }

  .logo-container {
    width: 700px;
  }
}

@media (max-width: 400px) {
  .solid-logo {
    width: 350px !important;
    margin-left: 1px;
  }

  .logo-container {
    margin-top: 60px;
    width: 300px;
    margin-left: 0px;
  }
}
