.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
    justify-content: center;
    margin-left: 25px;
  }

  .image-box {
    position: relative;
    flex: 1 1 40%;
    height: 500px;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(40% - 10px);
    margin-right: 70px;
    margin-bottom: 40px;

    .portfolio-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: 96%;
      z-index: 3;
      padding: 10px 20px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0,
        rgba(0, 0, 0, 1)
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 300;
      padding: 10px;
      letter-spacing: 1px;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #ffd700;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #ffd700;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, #646464, rgb(8, 7, 7));
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container.portfolio-page {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    h1.page-title {
      margin-left: 20px;
      margin-top: 20px;
    }

    .image-box {
      height: 300px;
      max-width: 100%;
      margin-left: 40px;
    }
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 16px !important;
    margin-left: 10px !important;
  }

  .description {
    font-size: 10px !important;
    margin-left: 10px !important;
  }

  .portfolio-page .image-box .content {
    padding-left: 2px !important;
  }
}
