.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #444;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #444;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
  }
}

iframe {
  border: none;
  display: block;
  margin: 0 auto;
}

.contact-wrap {
  background: #ffd601;
  float: right;
  width: 43%;
  height: 115%;
  letter-spacing: 2px;
}

.contact-icons {
  display: block;
  text-align: left;
  margin-left: 95px;

  .contact {
    font-size: 22px;
    margin-left: 16px;
    color: white;
    opacity: 0.9;
    color: #181818;
  }
}

.contacticon {
  margin-top: 40px;
  font-size: 35px;
}

.link {
  text-decoration: none;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
  .contact-wrap {
    float: none;
    margin: 0;
    width: 100%;
    height: 400px;
  }

  .contact-form {
    display: none;
  }

  .contact {
    font-size: 20px !important;
  }

  .contacticon {
    font-size: 20px;
  }

  .contact-icons {
    margin-left: 40px;
  }

  iframe {
    width: 300px;
    height: 180px;
    padding-top: 40px;
    margin-left: 40px;
  }
}
